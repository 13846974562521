.commission-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  margin: 40px 0;

  @media (min-width: 992px) {
    zoom: 0.9;
    flex-wrap: nowrap;
  }

  @media (min-width: 1600px) {
    margin: 7.7vh 0;
  }
  &__item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 10% 15%;

    @media (min-width: 600px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 5%;
    }

    @media (min-width: 992px) {
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0;
    }
  }
  &__text {
    color: #464646;

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 24px;
      margin: 0 auto 12px;
      background: #00a188;
    }
  }
}
