.instructions-block {
  $this: &;
  padding: 65px 25px 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 1360px;

  @media (min-width: 992px) {
    padding: 65px 50px 0;
  }
  @media (min-width: 1200px) {
    padding: 65px 7.5vw 0;
  }

  .fp-responsive & {
    padding-bottom: 65px;
  }

  &--odd {
    #{$this}__img-wrap {
      @media (min-width: 992px) {
        order: 1;
        padding: 10px 0 0 50px;
      }
    }
  }

  &__title {
    margin: 0 0 35px;
    color: #000;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 40px;
    }
    @media (min-width: 992px) {
      font-size: 40px;
    }

    span {
      color: $color-red;
    }

    &::after {
      content: "";
      display: block;
      height: 4px;
      width: 44px;
      margin: 25px auto 0;
      background: #be1d31;
    }
  }
  &__img {
    border-radius: 10px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 20px;
    max-width: 900px;
    width: 100%;
    margin: 20px auto 0;
    padding: 0;
    list-style: none;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1920px) {
      margin: 55px auto 0;
    }
  }
  &__list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__list-img-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 0 0 14px rgba(0,0,0, 12%);
    text-align: center;

    @media (min-width: 576px) {
      padding: 25px;
      width: 110px;
      height: 110px;
    }

    &--black {
      padding: 0 5px;
      background: #16181a;
    }
  }
  &__list-img {
    max-width: 100%;
    max-height: 100%;
  }
  &__list-link {
    display: inline-block;
    margin: 15px 0 0;
  }
  &__list-text {
    margin: 15px 0 0;
  }



  &__guide {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 35px 0 0;
    text-align: center;

    @media (min-width: 568px) {
      gap: 60px;
    }

    @media (min-width: 1920px) {
      margin: 55px 0 0;
    }
  }
  &__guide-elem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 130px;
    height: 40px;
    background: $color-green;
    border-radius: 40px;

    @media (min-width: 568px) {
      width: 160px;
      height: 60px;
    }

    @media (min-width: 768px) {
      width: 200px;
      height: 60px;
      border-radius: 50px;
    }
    @media (min-width: 1600px) {
      width: 240px;
      height: 80px;
      border-radius: 55px;
    }

    &--red {
      background: $color-red;
    }
  }
  &__guide-img {
    height: 22px;

    @media (min-width: 568px) {
      height: 30px;
    }

    @media (min-width: 768px) {
      height: 34px;
    }
    @media (min-width: 1600px) {
      height: 40px;
    }
  }
  &__guide-text {
    display: inline-block;
    margin: 10px 0 0;
    font-size: 14px;

    @media (max-width: 567px) {
      br {
        display: none;
      }
    }
  }

  &__note {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0 0;
    padding: 15px 25px;
    background: #f2f2f2;
    box-shadow: 0 0 20px 0 rgba(0,0,0, 8%) inset;
    border-radius: 10px;

    @media (min-width: 568px) {
      flex-direction: row;
    }
    @media (min-width: 1920px) {
      margin: 40px 0 0;
      padding: 25px 40px;
    }
  }
  &__note-img-wrap {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 80px;
    height: 80px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 14px rgba(0,0,0, 12%);
    text-align: center;

    @media (min-width: 568px) {
      margin: 0 40px 0 0;
    }

    @media (min-width: 1920px) {
      margin: 0 40px 0 0;
      padding: 25px;
      width: 110px;
      height: 110px;
    }
  }
  &__note-img {
    height: 40px;
    @media (min-width: 1920px) {
      height: 50px;
    }
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 20px 0 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    text-align: center;

    @media (min-width: 568px) {
      grid-template-columns: repeat(2,1fr);
    }
    @media (min-width: 960px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (min-width: 1920px) {
      margin: 50px 0 0;
      font-size: 16px;
    }
  }
  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background: #f0f0f0;
    border-radius: 4px;
    counter-increment: steps;

    &::before {
      content: counter(steps);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6px 6px 20px;
      width: 50px;
      height: 50px;
      color: $color-green;
      background: #fff;
      border: 2px solid $color-green;
      outline: 6px solid #fff;
      border-radius: 50%;
      font-size: 30px;
      font-weight: bold;
    }

    @media (max-width: 567px) {
      br {
        display: none;
      }
    }
  }
}
