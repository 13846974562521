.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #101214;
  color: #fff;
  text-align: center;

  &__contain {
    padding: 0 25px;
    margin: auto;
    max-width: 1360px;
  }
  &__title {
    margin: 0;
    font-weight: bold;
    @include fluid-type(20px, 50px);
    text-transform: uppercase;
  }
  &__404 {
    display: block;
    @include fluid-type(100px, 200px);
    font-size: 200px;
    line-height: 1;
  }
  &__btn-wrap {
    margin: 25px 0 0;
  }
}
