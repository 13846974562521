.locales {
  $this: &;
  display: none;
  position: relative;

  @media (min-width: 992px) {
    display: block;
  }

  &.open {
    #{$this}__dropdown {
      display: block;
    }
    #{$this}__arrow {
      transform: rotate(180deg);
    }
  }

  &__current {
    display: flex;
    align-items: center;
    padding: 0 4px;
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      color: #98999A;

      #{$this}__arrow {
        fill: #98999A;
      }
      #{$this}__img-current {
        opacity: 0.8;
      }
    }
  }
  &__img-current {
    margin: 0 14px 0 0;
    width: 34px;
    height: 34px;
    border: 6px solid rgba(247,247,247, 0.1);
    border-radius: 50%;
    transition: all 0.3s;
  }
  &__arrow {
    flex-shrink: 0;
    margin-left: 10px;
    fill: #fff;
    transition: all 0.3s;
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 10px 0 0;
    padding: 0;
    background: #181a1f;
    color: #fff;
    border-radius: 6px;
    list-style: none;
    overflow: hidden;
  }
  &__item {
    border-bottom: 1px solid #2f3135;

    &:last-child {
      border-bottom: none;
    }
  }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 20px 8px 8px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s all;

    &:hover {
      background: #272a30;
    }
  }
  &__img {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }

  &__arrow-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    margin: auto;
    fill: #fff;
    transform: rotate(-90deg);
  }
}
