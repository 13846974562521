.dropdown-menu-mob {
  $this: &;
  list-style: none;
  padding: 0;

  &.open {
    #{$this}__dropdown {
      display: block;
    }
    #{$this}__arrow {
      transform: rotate(-180deg);
    }
  }

  &__current {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 40px 10px 15px;
    width: 100%;
    color: #fff;
    background: transparent;
    border: none;
    outline: none;
    border-top: 1px solid rgba(0,0,0,.3);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.05);
    text-shadow: 0 1px 0 rgba(0,0,0,.5);
    text-decoration: none;
    text-align: left;

    &--flag {
      padding: 6px 40px 6px 15px;
    }
  }

  &__dropdown {
    display: none;
    list-style: none;
    padding: 0;
  }
  &__link {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 30px;
    border-top: 1px solid rgba(0,0,0,.3);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.05);
    text-shadow: 0 1px 0 rgba(0,0,0,.5);
    text-decoration: none;

    &--flag {
      padding: 6px 30px;
    }
  }
  &__number {
    color: #00a188;
    margin-left: 10px;
  }
  &__arrow {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    margin: auto;
    fill: #fff;
    transition: all 0.3s;
  }
  &__flag {
    margin: 0 8px 0 0;
    width: 26px;
    height: 26px;
    border: 4px solid rgba(247, 247, 247, 0.1);
    border-radius: 50%;
    transition: all 0.3s;
  }
  &__flag-text {
    text-transform: uppercase;
  }
}
