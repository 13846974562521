.contacts {

  &__box {
    margin: 30px 0 0;
    text-align: center;
  }
  &__box-img {
    display: block;
    margin: 0 auto 25px;
  }


  &__text {
    margin: 25px 0 0;
    font-family: $Roboto;
    font-weight: 300;
    font-size: 15px;
    letter-spacing: 0.04em;
    text-align: center;
    line-height: 1.5em;
    color: #464646;
  }
}
