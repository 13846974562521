.affiliates {
  max-height: calc(100vh - 80px);
  padding: 0 10px;
  overflow: auto;

  @media (min-width: 768px) {
    margin-top: 80px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
    margin: 0;
  }
  // ------- Firefox -------
  scrollbar-color: #ccc transparent;
  scrollbar-width: thin;

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px 20px;
    padding: 0 0 30px;
    overflow: hidden;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__link {
    display: flex;
    max-height: 40px;
  }
  &__img {
    display: block;
    width: auto;
    max-height: 100%;
    object-fit: contain;
  }
}
