.modal {
  max-width: 415px;
  background: #fff;
  box-shadow: 0 0 75px 0 rgba(53, 152, 219, 1);
  border-radius: 2px;
  padding: 0 20px 35px;


  @media (min-width: 500px) {
    padding: 0 35px 35px;
  }
  &.terms {
    max-width: 740px;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6d8e2;
    margin-bottom: 30px;
  }
  &__title-h2 {
    color: #111314;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    padding: 30px 0;
    &::after {
      content: "";
      background: #000;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &--red {
      &::after {
        background: #be1d31;
      }

    }
    &--green {
      &::after {
        background: #00a188;
      }
    }
  }

  &__close {
    &:hover svg{
      fill: #9b9da7
    }

    svg {
      transition: 0.25s all ease;
      backface-visibility: hidden;

      @media (max-width: 499px) {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__content {
    > div {
      display: none;
    }
    > div.active {
      display: block;
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;


  &__item {
    padding: 30px 0;
    margin-right: 20px;
    position: relative;

    @media (min-width: 500px) {
      margin-right: 40px;
    }


    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;

    @media (min-width: 500px) {
      font-size: 24px;
    }

    &.active {
      color: #111314;
      font-weight: bold;
      &::after {
        content: "";
        background: #000;
        height: 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    &--reg {
      color: #be1d31;

      &.active {
        &::after {
          background: #be1d31;
        }
      }
    }
    &--si {
      color: #00a188;

      &.active {
        &::after {
          background: #00a188;
        }
      }
    }
  }
}


.blocker {
  padding: 20px 0;
}
