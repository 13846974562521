.advantages {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 30px;

  @media (min-width: 992px) {
    margin-top: 0;
  }

  &__item {
    margin: 15px 0;
    flex: 0 0 100%;
    transition: transform 0.9s cubic-bezier(0.33, 0, 0.35, 1), opacity 0.9s cubic-bezier(0.33, 0, 0.35, 1);
    transform: translateY(60px);
    opacity: 0;

    &--active {
      transform: translateY(0px);
      opacity: 1;

      &:nth-child(2) {
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        transition-delay: 0.4s;
      }
      &:nth-child(4) {
        transition-delay: 0.6s;
      }
      &:nth-child(5) {
        transition-delay: 0.8s;
      }
      &:nth-child(6) {
        transition-delay: 1.0s;
      }
    }

    @media (min-width: 768px) {
      flex: 1 1 50%;
      padding: 0 20px;
    }

    @media (min-width: 992px) {
      flex: 1 1 33.3333%;
      padding: 0 30px;
    }
  }
  &__img {
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.12);
  }
  &__title {
    font-size: 20px;
    color: #464646;
    margin: 20px 0;
  }
  &__description {
    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 25px;
      margin: 0 auto 20px;
      background: #00a188;
    }
  }
}