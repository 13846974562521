.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 10px 0;
  background: #101214;
  transition: 0.25s all ease;
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 auto;
    max-width: 1720px;

    @media (min-width: 1200px) {
      padding-left: 35px;
      padding-right: 35px;
    }
    @media (min-width: 1600px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
  &__logo {
    width: 134px;
    display: block;
    img {
      vertical-align: middle;
    }
  }
  &__menu {
    display: flex;
    align-items: center;

    @media (min-width: 992px) {
      padding-left: 95px;
    }
    @media (min-width: 1200px) {
      padding-left: 105px;
    }
    @media (min-width: 1920px) {
      padding-left: 0;
    }
  }
  &__btn {
    display: none;

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
  &__mob {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    width: 60px;
    height: 60px;
    margin: auto;
    padding: 10px;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.25s all ease;

    @media (min-width: 992px) {
      left: 25px;
      right: initial;
    }
    @media (min-width: 1200px) {
      left: 35px;
    }
    @media (min-width: 1920px) {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline-width: 0;
    }

    &.opened {
      .header__mob-bar-top {
        transform: rotate(45deg);
        transform-origin: 15% 15%;
      }
      .header__mob-bar-mid {
        opacity: 0;
      }
      .header__mob-bar-bot {
        transform: rotate(-45deg);
        transform-origin: 15% 95%;
      }
    }
    & [class*='bar-'] {
      background: #fff;
      display: block;
      transform: rotate(0deg);
      transition: .2s ease all;
      border-radius: 4px;
      height: 4px;
      width: 100%;
      margin-bottom: 5px;
    }

    &-bar-bot {
      margin-bottom: 0 !important;
    }
  }
}
