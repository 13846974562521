.dropdown-menu {
  $this: &;
  position: relative;

  &.open {
    #{$this}__dropdown {
      display: block;
    }
    #{$this}__current {
      text-decoration: none;

      &::after {
        top: 0
      }

      #{$this}__current-text {
        transform: translateY(300%);
      }
      #{$this}__arrow {
        fill: #98999A;
      }
    }
    #{$this}__arrow {
      transform: rotate(180deg);
    }
  }

  &__current {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4px 0;
    background: transparent;
    color: #f7f7f7;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s all ease;

    &::after {
      content: attr(data-hover);
      position: absolute;
      left: 0;
      top: -100%;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #98999A;
      transition: all 0.3s;
    }

    &.active {
      color: #98999A;
      cursor: default;

      &:hover {
        &::after {
          top: -100%;
        }
        #{$this}__current-text {
          transform: none;
        }
      }

    }
    &:hover {
      text-decoration: none;

      &::after {
        top: 0
      }

      #{$this}__current-text {
        transform: translateY(300%);
      }
      #{$this}__arrow {
        fill: #98999A;
      }
    }
  }
  &__current-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    backface-visibility: hidden;
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 10px 0 0;
    padding: 0;
    min-width: 190px;
    background: #181a1f;
    color: #fff;
    border-radius: 6px;
    list-style: none;
    overflow: hidden;
  }
  &__item {
    border-bottom: 1px solid #2f3135;

    &:last-child {
      border-bottom: none;
    }
  }
  &__link {
    position: relative;
    display: block;
    padding: 8px 12px;
    background: transparent;
    color: #f7f7f7;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition: 0.2s all ease;

    &.active {
      background: #272a30;
    }
    &:hover {
      background: #272a30;
    }


    &--dropdown {
      display: flex;
      align-items: center;
      padding: 4px 0;
      &::after {
        padding: 0;
      }
    }
  }
  &__arrow {
    flex-shrink: 0;
    margin-left: 10px;
    fill: #fff;
    transition: all 0.3s;
  }
}
