* {
  box-sizing: border-box;
}

body {
  height: 100%;
  background: #fff;
  color: #464646;
  font-family: $RobotoC;
}

.contain {
  padding: 65px 25px 0;
  margin: auto;
  max-width: 1360px;

  @media (min-width: 992px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (min-width: 1200px) {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }

  .fp-responsive & {
    padding-bottom: 65px;
  }
}

.space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.link {
  text-decoration: none;
  padding-bottom: 4px;
  color: #00a188;
  border-bottom: 1px solid transparent;
  transition: 0.25s all ease;
  backface-visibility: hidden;

  &:hover {
    text-decoration: none;
    border-color: #00a188;
  }

  &--grey {
    color: #98999A;

    &:hover {
      border-color: #98999A;
    }
  }
}
.link-secondary {
  text-decoration: none;
  padding-bottom: 4px;
  color: #464646;
  border-bottom: 2px solid #00a188;
  transition: 0.25s all ease;
  backface-visibility: hidden;

  &:hover {
    color: $color-green;
    text-decoration: none;
  }

  &--red {
    border-color: $color-red;

    &:hover {
      color: $color-red;
    }
  }
}

.link-hd {
  color: #464646;
  text-decoration: none;

  &:hover {
    color: #4c4c4c;
  }
}

.text-hover {
  &:hover {
    .text-hover__wrap::after {
      top: 0
    }
    .text-hover__text  {
      transform: translateY(300%);
    }
  }

  &__wrap {
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    display: inline-block;
    margin: 0;
    &::after {
      content: attr(data-hover);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      left: 0;
      top: -100%;
      transition: all 0.3s;
    }
  }

  &__text {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    backface-visibility: hidden;
    word-break: break-word;
  }
}

img {
  max-width: 100%;
  height: auto;
}

