.socials {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin: 30px 0 0;
  padding: 0;
  list-style: none;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border: 1px solid #b8babd;
    border-radius: 50px;
    transition: all 0.3s ease;

    &:hover {
      .socials__icon {
        fill: #fff;
      }
    }

    &--facebook {
      &:hover {
        background: #2361bc;
        border-color: #fff;
      }
    }
    &--twitter {
      &:hover {
        background: #4785e1;
        border-color: #fff;
      }
    }
    &--instagram {
      &:hover {
        background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
        border-color: #fff;
      }
    }
  }
  &__icon {
    fill: #b8babd;
    transition: all 0.3s ease;
  }
}
