.mobile-menu {
  position: absolute;
  top:0;

  &.opened {

    .mobile-menu__wrap {
      left: 0;
    }
    .mobile-menu__close {
      display: block;
    }
  }

  &__wrap {
    position: fixed;
    left: -300px;
    background: #333;
    transition: left .35s;
    height: 100vh;
    width: 240px;
    z-index: 112;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    @media (min-width: 400px) {
      width: 300px;
    }
  }

  &__close {
    display: none;
    position: fixed;
    z-index: 111;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.7);
  }

  &__logo {
    text-align: center;
  }
  &__logo-img {
    max-width: 135px;
  }

  &__list {
    border-bottom: 1px solid rgba(0,0,0,.3);
    box-shadow: 0 1px 0 rgba(255,255,255,.05);
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__link {
    display: block;
    color: #fff;
    padding: 10px 15px;
    border-top: 1px solid rgba(0,0,0,.3);
    box-shadow: inset 0 1px 0 rgba(255,255,255,.05);
    text-shadow: 0 1px 0 rgba(0,0,0,.5);
    text-decoration: none;
  }
  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 0 15px;

    a {
      margin-bottom: 20px;
    }
  }
}
