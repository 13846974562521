.form {
  font-family: $Roboto;
  font-size: 15px;
  font-weight: 300;

  &__group {
    margin-bottom: 20px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }

    &--two {
      display: flex;
      justify-content: space-between;
    }
    &--center {
      text-align: center;
    }
  }
  &__group--error {
    .form__input {
      border-color: #ff2e2e;
    }
  }
  &__label {
    font-size: 15px;
    font-weight: 300;
    color: #a3a3a3;
    position: absolute;
    left: 15px;
    top: -8px;
    background: #fff;
    padding: 0 4px;
    opacity: 0;
    transition: 0.25s all ease-in;
    z-index: 2;

    &.active {
      opacity: 1;
    }
  }
  &__input {
    border: 1px solid #d6d8e2;
    border-radius: 24px;
    padding: 12px 18px;
    display: block;
    width: 100%;
    outline: none;
    transition: 0.25s all ease-in;
    position: relative;

    &:focus {
      box-shadow: 0 31px 42px 0 rgba(0, 0, 0, 0.25);
      z-index: 1;

      &::placeholder {
        opacity: 0;
      }
      + .form__label {
        opacity: 1;
      }
    }
  }
  &__checkbox {
    display: none;

    & + span {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 13px;
      height: 13px;
      border: 1px solid #d9dbe4;
      border-radius: 2px;
      margin-right: 10px;
    }

    &:checked + span {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 5px;
        height: 5px;
        background: #00a188;
      }

    }
  }
  &__error {
    font-size: 10px;
    color: #ff2e2e;
    position: absolute;
    bottom: -14px;
    text-align: right;
    right: 0;
    left: 0;
    font-weight: normal;
    z-index: 3;

    @media (min-width: 400px) {
      font-size: 12px;
      bottom: -16px;
      padding-left: 20px;

      &::before {
        content: '';
        border-left: 1px solid #ff2e2e;
        border-bottom: 1px solid #ff2e2e;
        width: 6px;
        height: 10px;
        display: inline-block;
        position: relative;
        top: -2px;
        margin-right: 6px;
      }
    }
  }
  &__field-req {
    display: none;
    @media (min-width: 400px) {
      display: block;
    }
  }
}

.login-form {

  .form__error {
    position: relative;
    bottom: inherit;
    top: -15px;
    padding-left: 0;
    text-align: center;
    &::before {
      display: none;
    }
  }
}
