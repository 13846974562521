.about-section {
  background: #fbfcfc;
  &__box {
    display: flex;
    flex-wrap: wrap;
    margin:  25px -25px 0;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
    }
    @media (min-width: 1200px) {
      margin:  calc(10vh - 75px) -30px 0;
    }
  }
  &__item {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 25px;
    font-family: $Roboto;
    font-weight: 300;
    font-size: 15px;
    color: #464646;
    letter-spacing: 0.01em;
    text-align: center;
    line-height: 1.66em;
    margin: 30px 0;
    @media (min-width: 768px) {
      flex: 0 0 33%;
      max-width: 33%;
      margin: 15px 0;
    }

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: 24px;
      margin: 0 auto 12px;
      background: #00a188;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -42px;

      @media (min-width: 768px) {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &__big-text {
    font-size: 22px;
    color: #464646;
    text-align: center;
    margin: 35px 0 0;

    @media (min-width: 768px) {
      font-size: 28px;
    }
    @media (min-width: 1200px) {
      margin: 4vh 0 0;
      font-size: 36px;
    }
  }

  &__btn {
    margin: 45px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 500px) {
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

    }

    @media (min-width: 1200px) {
      margin: 10vh auto 40px;
    }

    a {
      margin-bottom: 15px;
      @media (min-width: 500px) {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    display: none;

    @media (min-width: 992px) {
      display: block;
      text-align: center;
      margin: 15px 0 30px;
    }
  }

  &__operator-info {
      display: block;
      text-align: center;
      margin: 15px 0 70px;
  }

  .fp-tableCell {
    position: relative;
  }
}
