.first-section {
  position: relative;
  background: #101214;
  background-size: cover;

  .fp-responsive  & {
    height: auto !important;
    .fp-tableCell {
      height: auto !important;
    }
  }

  &--instructions {
    background: url("~assets/images/page-instructions/bg-instructions-768w.webp") no-repeat bottom/cover;

    @media (min-width: 768px) {
      background-image: url("~assets/images/page-instructions/bg-instructions-1200w.webp");
    }
    @media (min-width: 1400px) {
      background-image: url("~assets/images/page-instructions/bg-instructions.png");
    }
    @media (min-width: 1900px) {
      background-image: url("~assets/images/page-instructions/bg-instructions-1920w.webp");
    }
  }
  &--affiliates {
    height: 100vh;
    overflow: auto;
    background: url("~assets/images/page-instructions/bg-instructions-768w.webp") no-repeat bottom/cover;

    @media (min-width: 768px) {
      background-image: url("~assets/images/page-instructions/bg-instructions-1200w.webp");
    }
    @media (min-width: 1400px) {
      background-image: url("~assets/images/page-instructions/bg-instructions.png");
    }
    @media (min-width: 1900px) {
      background-image: url("~assets/images/page-instructions/bg-instructions-1920w.webp");
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    padding-top: 82px;

    @media (min-width: 992px) {
      padding-top: 120px;
    }
    @media (min-width: 1200px) {
      max-width: 1120px;
      margin: auto;
      padding-top: 0;
      padding-left: 7.5vw;
      padding-right: 7.5vw;
    }
    @media (min-width: 1500px) {
      max-width: 1360px;
    }

    &--instructions {
      justify-content: center;
    }
  }

  &__block-left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 15vw;
    min-width: 50px;


    @media (min-width: 576px) {
      min-width: 70px;
    }
    @media (min-width: 768px) {
      width: 7.5vw;
    }
    @media (min-width: 992px) {
      width: 25vw;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  &__email {
    background: #00a188;
    flex: 1 1 50%;
    width: 100%;
    color:  #fff;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    @media (min-width: 992px) {
      max-height: 130px;
    }
    @media (min-width: 1200px) {
      position: absolute;
      top: 0;
      right: 0;
      width: 56px;
      max-height: none;
      height: 300px;
      border-radius: 10px 0 0 10px;
    }

    p {
      display: none;

      @media (min-width: 576px) {
        display: block;
        margin-top: 30px;
      }
      @media (min-width: 1200px) {
        margin: 0 0 0 10px;
      }
    }
  }
  &__email-wrap {
    @media (min-width: 1200px) {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }
  &__sing-up {
    width: 100%;
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color:  #fff;
    font-weight: bold;
    text-align: center;
    background: #be1d31;
    transition: 0.2s ease-in-out;

    img {
      max-width: 60%;
    }

    &:hover {
      background: lighten(#be1d31, 10%);
    }
    p {
      margin-top: 20px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        margin-top: 30px;
      }
    }
  }

  &__block-right {
    display: flex;
    flex-direction: column;
    width: 15vw;
    min-width: 50px;
    text-align: center;

    @media (min-width: 576px) {
      min-width: 70px;
    }
    @media (min-width: 768px) {
      width: 7.5vw;
    }
    @media (min-width: 1200px) {
      display: none;
    }
  }
  &__sign-in {
    flex: 1 1 50%;
    background: #00a188;
    color:  #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 0;
    transition: 0.2s ease-in-out;
    &:hover {
      background: lighten(#00a188, 10%);
    }

    p {
      display: none;

      @media (min-width: 576px) {
        display: block;
        margin-top: 10px;
        text-transform: uppercase;
      }
      @media (min-width: 1600px) {
        margin-top: 3.4vh;
      }
    }
  }
  &__next {
    flex: 1 1 50%;
    background: #101113;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color:  #fff;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
      background: lighten(#101113, 10%);
    }

    @media (min-width: 1200px) {
      position: absolute;
      right: 56px;
      width: 56px;
      height: 300px;
      background: transparent;

      &:hover {
        background: transparent;
      }
    }

    p {
      display: none;
      @media (min-width: 1200px) {
        white-space: nowrap;
      }
      @media (min-width: 1600px) {
        display: block;
        margin-top: 30px;
        text-transform: uppercase;
        transform: rotate(90deg);
        font-size: 13px;
        color: #a2a4a9;
        letter-spacing: 0.1em;
      }
    }
  }
  &__next-arrow {
    margin: 40px 0 35px;
    @media (min-width: 1600px) {
      margin: 4.6vh 0 4.2vh;
    }
  }

  &__block-desktop {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 300px;
      margin: auto;
    }
  }

  &__logos {
    display: flex;
    flex-direction: column;
    padding: 0 10px 20px;

    @media (min-width: 1200px) {
      padding: 0;
    }
  }
  &__logos-title {
    font-family: $RobotoC;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    line-height: 1.1;
    text-transform: uppercase;
    margin: 0 0 10px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 34px;
      margin: 0 0 30px;
    }

    @media (min-width: 992px) {
      font-size: 48px;
      text-align: left;
    }
  }
  &__logos-title-small {
    color: #BE1D31;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      font-size: 20px;
      margin-top: 0;
    }
  }
  &__desc {
    color: #fff;
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 992px) {
      font-size: 20px;
      margin-top: 0;
    }
  }

  &__logos-wrap {
    flex-grow: 1;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(5,1fr);

    @media (min-width: 768px) {
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: repeat(4,1fr);
    }
    @media (min-width: 1200px) {
      grid-gap: 20px 25px;
      grid-template-columns: repeat(5,1fr);
      grid-template-rows: repeat(3,1fr);
      max-height: 440px;
    }
  }
  &__logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    border-radius: 10px;
    background: #181A1F;
    border:  1px solid transparent;
    transition: 0.25s all ease;

    &:hover {
      border-color: #fff;
    }
  }
  &__logo-img {
    max-width: 80%;

    &--bob,
    &--spinia,
    &--cookie,
    &--dragonslots,
    &--avalon,
    &--masonslots,
    &--national,
    &--bizzo,
    &--limewin {
      max-width: 60%;
    }
  }

  &__banner {
    flex-grow: 1;
    min-height: calc(100vh - 70px);
    padding: 10vw 5vw 0;
    text-align: center;

    @media (min-width: 1200px) {
      padding: 0;
      max-width: 35%;
      min-height: auto;
      text-align: left;
    }

    &--instructions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__banner-title {
    margin: 0 0 30px;
    font-family: $RobotoC;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    line-height: 1.4;
    text-transform: uppercase;
    text-align: center;

    @media (min-width: 576px) {
      font-size: 48px;
    }
    @media (min-width: 992px) {
      text-align: left;
    }

    &--instructions {
      text-align: center;

      &::after {
        background: #be1d31;
        content: "";
        display: block;
        height: 4px;
        margin: 25px auto 0;
        width: 60px;
      }
    }
  }
  &__banner-desc {
    margin: 0;
    color: #fff;
    font-family: $Roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6;

    @media (min-width: 576px) {
      font-size: 20px;
    }
  }

  .fp-tableCell  {
    @media (max-width: 1199px) {
      vertical-align: bottom;
    }
  }
}
