#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  width: 10px;
  height: 10px;
  border: 1px solid #d6d8e2;
  background: none;
  transition: 0.2s all ease;
}

#fp-nav ul li, .fp-slidesNav ul li {
  margin-bottom: 12px;
  margin-top: 12px;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  width: 10px;
  height: 10px;
  border-color: #00a188;
  background: #00a188;
  margin: -2px 0 0 -2px;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
  margin: -2px 0 0 -2px;
  border-color: #00a188;
}

#fp-nav {
  @media (min-width: 992px) {
    padding-left: calc((100vw - 1080px) / 2);
  }

  @media (min-width: 1200px) {
    padding-left: calc((100vw - 1360px) / 2);
  }

  @media (min-width: 1600px) {
    padding-left: 7.5vw;
    &::after {
      content: "";
      height: 70px;
      width: 1px;
      background: #d6d8e2;
      display: block;
      margin: 90px 0 0 15px;
      transition: 0.2s all ease;
    }
  }

  a:hover + div {
    opacity: 1;
  }
  ul li .fp-tooltip.left {
    color: #464646;
    top: 2px;
    left: 30px;
    transition: 0.25s opacity ease-in;
  }
}

.fp-viewing-firstPage {
  .first-section__wrap {
    padding-top: 70px;

    @media (min-width: 992px) {
      padding-top: 90px;
    }
    @media (min-width: 1200px) {
      padding-top: 50px;
    }
  }
  #fp-nav {

    @media (min-width: 1400px) {
      padding-left: 7.5vw;
    }

    ul::after {
      background: #3f4143;
    }
    ul li .fp-tooltip.left {
      color: #f7f7f7;
    }
  }
  #fp-nav ul li a span,
  .fp-slidesNav ul li a span {
    border-color: #3f4143;
  }

  .header {
    background: transparent;
    padding-top: 7.5vh;
  }
  @media (min-width: 992px) {
    .main-menu,
    .header__menu{
      flex-grow: 1;
    }
    .main-menu__nav-list-item {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.fp-viewing-firstPage.fp-responsive {
  .first-section__wrap {
    @media (min-width: 1200px) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }
}
body.fp-responsive {
  overflow: visible;

  .header {
    background: #101214;
    padding-top: 10px;
  }
}
