.affiliate-footer {
  background: #292b30;
  padding: 15px 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  &__link {
    display: block;
    max-height: 32px;
    transition: 0.25s all ease;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: inherit !important;
      max-height: 32px;
    }
    &--text {
      color: #DF2F45;
      display: inline;
      text-decoration: none;
    }
  }
  &__text {
    color: #fff;
  }
}
