.title-block {
  text-align: center;

  &__h2 {
    font-family: $RobotoC;
    font-size: 34px;
    font-weight: bold;
    margin: 0;
    color: #000;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 40px;
    }

    @media (min-width: 992px) {
      font-size: 48px;
    }
    span {
      display: block;
    }
    &::after {
      content: "";
      display: block;
      height: 4px;
      width: 44px;
      margin: 25px auto 0;
      background: #00a188;
    }
  }


  &__descr {
    font-size: 15px;
    color: #464646;
    margin-top: 25px;
  }
}
