.main-menu {
  display: none;

  @media (min-width: 1920px) {
    display: block;
    margin-left: 90px;
    transition: 0.25s all ease;
  }

  &__nav-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;

    @media  (min-width: 992px) {
      font-size: 12px;
    }
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  &__nav-list-item {
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media  (min-width: 992px) {
      margin-right: 15px;
    }
    @media  (min-width: 1300px) {
      margin-right: 35px;
    }
  }

  &__nav-list-link {
    position: relative;
    display: block;
    padding: 4px 0;
    color: #f7f7f7;
    text-decoration: none;
    overflow: hidden;
    transition: 0.2s all ease;

    &.active {
      color: #98999A;
      cursor: default;
    }
    &:hover {
      text-decoration: none;
    }

    &::after {
      content: attr(data-hover);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      left: 0;
      top: -100%;
      color: #98999A;
      transition: all 0.3s;
    }


    &:hover {
      &::after {
        top: 0
      }
      span {
        transform: translateY(300%);
      }
    }
  }

  &__nav-item-text {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    backface-visibility: hidden;
  }
}

/*TODO: fix */
@media (min-width: 1200px) {
  .fp-viewing-firstPage {
    .main-menu {
      flex-grow: 1;
      margin-left: calc(17vw  - 134px);
    }
  }
}

@media (min-width: 1500px) {
  .fp-viewing-firstPage {
    .main-menu {
      margin-left: calc(21.6vw  - 134px);
      margin-right: 3vw;
    }
  }
}
@media (min-width: 1920px) {
  .fp-viewing-firstPage {
    .main-menu {
      margin-left: 180px
    }
  }
}


