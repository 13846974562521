.section {
  background: #fff;

  &--grey,
  &--even {
    background: #f7f7f7;
  }

  .fp-responsive  & {
    height: auto !important;
    .fp-tableCell {
      height: auto !important;
    }
  }

  &__btn-c {
    text-align: center;
    margin: 0 0 50px;

    @media (min-width: 992px) {
      margin: 0;
    }
  }
}
