.terms {
  &__content {
    max-height: 540px;
    padding-right: 20px;
    height: 60vh;

    h3 {
      font-family: $Roboto;
      color: #111314;
      font-size: 24px;
    }

    p {
      font-family: $Roboto;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.7em;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        padding-bottom: 25px;  //nicescroll fixing
      }
    }
  }
}
