.btn {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 11px 15px;
  min-width: 125px;
  color: #f7f7f7;
  border: none;
  outline: none;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 20px;
  line-height: 1em;
  text-transform: uppercase;
  font-weight: bold;
  backface-visibility: hidden;
  overflow: hidden;
  transition: 0.2s ease-in-out;

  &:hover {
    &::after {
      top: 0
    }
    span {
      transform: translateY(300%);
    }
  }

  &::after {
    content: attr(data-hover);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: -100%;
    transition: all 0.3s;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    backface-visibility: hidden;
  }

  &--green {
    background: #00a188;

    &:hover {
      background: lighten(#00a188, 10%);
    }

  }
  &--red {
    background: #be1d31;
    &:hover {
      background: lighten(#be1d31, 10%);
    }
  }
}

